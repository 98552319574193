import { Variants } from "framer-motion";

export const EASE_CUBIC_BEZIER = [0.45, 0.04, 0.37, 0.93];

export const PLAY_BUTTON_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
  shown: {
    opacity: 1,
    scale: 1,
  },
};

export const SLIDE_IN_FRON_BOTTOM: Variants = {
  hidden: {
    y: "100%",
    transition: {
      ease: EASE_CUBIC_BEZIER,
    },
  },
  shown: {
    y: "0",
    transition: {
      ease: EASE_CUBIC_BEZIER,
    },
  },
};
