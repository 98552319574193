import React, { useContext } from "react";
import { navigate, useIntl } from "gatsby-plugin-intl";

/* Data things */
import { LayoutContext } from "../contexts";

/* Presentational things */
import Header from "./header";
import { Room } from "../components/room";
import { Button } from "../components/button";
import { ArrowLeft } from "../assets/icons";
import { AnimatePresence, m } from "framer-motion";
import { SLIDE_IN_FRON_BOTTOM } from "../constants/animation";

// @ts-ignore
export default function Layout(props) {
  const { figures, current } = useContext(LayoutContext);
  const { locale } = useIntl();
  const pathName = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <div>
      <Header />
      <AnimatePresence>{props.children}</AnimatePresence>
      <AnimatePresence>
        {pathName !== `/${locale}/` && (
          <m.nav
            variants={SLIDE_IN_FRON_BOTTOM}
            initial="hidden"
            animate="shown"
            exit="hidden"
            key="nav"
            className="fixed bottom-0 z-30 flex h-16 w-full max-w-full gap-4 overflow-y-visible overflow-x-scroll border-t bg-gray-50 p-1 backdrop-blur-sm [scroll-snap-type:x_mandatory]"
          >
            <Button
              key="back"
              onClick={() => navigate("/")}
              className="aspect-square h-full snap-start scroll-m-2 rounded-3xl border bg-gray-50 p-0 text-gray-900 hover:text-gray-900"
              secondary
              icon={<ArrowLeft className="stroke-2" />}
              title="Vissza a főoldalra"
            />
            <AnimatePresence mode="popLayout">
              {figures.map((figure, index) => (
                <Room key={figure.slug} className="h-full snap-start scroll-m-2" firgure={figure} index={index + 1} withoutText />
              ))}
            </AnimatePresence>
          </m.nav>
        )}
      </AnimatePresence>
    </div>
  );
}
