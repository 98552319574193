import React from "react";
import { Link } from "gatsby-plugin-intl";
import { HTMLMotionProps, m } from "framer-motion";

interface IProps extends HTMLMotionProps<"button"> {
  to?: string;
  href?: string;
  title?: string;
  ariaLabel?: string;
  className?: string;
  fullWidth?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  blank?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  icon?: JSX.Element;
  trailingIcon?: JSX.Element;
  leadingIcon?: JSX.Element;
}

export function Button({
  secondary,
  tertiary,
  title,
  fullWidth,
  type = "button",
  className,
  onClick,
  ariaLabel = title,
  to,
  href,
  icon,
  trailingIcon,
  leadingIcon,
  blank = false,
  ...props
}: IProps) {
  const buttonClass = `button ${tertiary ? "button-tertiary" : secondary ? "button-secondary" : "button-primary"}${fullWidth ? " w-full" : ""}${className ? " " + className : ""}`;

  return to ? (
    <Link to={to} className={buttonClass} title={title} onClick={onClick} aria-label={ariaLabel}>
      {leadingIcon && leadingIcon}
      {icon ? icon : title}
      {trailingIcon && trailingIcon}
    </Link>
  ) : href ? (
    <a href={href} className={buttonClass} title={title} onClick={onClick} aria-label={ariaLabel} target={blank ? "_blank" : ""}>
      {leadingIcon && leadingIcon}
      {icon ? icon : title}
      {trailingIcon && trailingIcon}
    </a>
  ) : (
    <m.button type={type} className={buttonClass} title={title} onClick={onClick} aria-label={ariaLabel} {...props}>
      {leadingIcon && leadingIcon}
      {icon ? icon : title}
      {trailingIcon && trailingIcon}
    </m.button>
  );
}
