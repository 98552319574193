import React from "react";
import { GatsbyBrowser, GatsbySSR } from "gatsby";

import Layout from "./src/layouts";
import { AnimatePresence, LazyMotion, domMax } from "framer-motion";
import { IntlProvider } from "gatsby-plugin-intl";
import { LayoutProvider } from "./src/contexts";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] | GatsbySSR["wrapRootElement"] = ({ element }) => <LayoutProvider>{element}</LayoutProvider>;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] | GatsbySSR["wrapPageElement"] = ({ element }) => {
  return (
    <LazyMotion features={domMax}>
      <AnimatePresence>
        <IntlProvider locale={element.props.locale} key={element.props.locale} messages={element.props.messages}>
          <Layout>{element}</Layout>
        </IntlProvider>
      </AnimatePresence>
    </LazyMotion>
  );
};
