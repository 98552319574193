import React from "react";

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width="1.5em" height="1.5em" {...props}>
      <path
        d="M11.0078 22.375C10.7422 22.6406 10.7344 23.0938 11.0078 23.3594C11.2812 23.625 11.7344 23.625 12 23.3594L17 18.3594L22 23.3594C22.2656 23.625 22.7266 23.6328 22.9922 23.3594C23.2578 23.0859 23.2578 22.6406 22.9922 22.375L17.9922 17.3672L22.9922 12.3672C23.2578 12.1016 23.2656 11.6484 22.9922 11.3828C22.7188 11.1094 22.2656 11.1094 22 11.3828L17 16.3828L12 11.3828C11.7344 11.1094 11.2734 11.1016 11.0078 11.3828C10.7422 11.6562 10.7422 12.1016 11.0078 12.3672L16.0078 17.3672L11.0078 22.375Z"
        fill="currentColor"
      />
    </svg>
  );
};
