import React, { useContext } from "react";
import clsx from "clsx";
import { Variants, m } from "framer-motion";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import { LayoutContext } from "../../contexts";

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  firgure: Queries.FigurePreviewFragment;
  withoutText?: boolean;
  home?: boolean;
  index: number;
}

const SLIDE_IN_FRON_BOTTOM: Variants = {
  hidden: {
    opacity: 0,
    y: "100%",
  },
  shown: (home: number) => ({
    opacity: 1,
    delay: home * 0.3,
    y: 0,
    transition: {
      duration: 0.3,
    },
  }),
};

export const Room = ({ firgure, index, className, withoutText = false, home = false, ...props }: IProps) => {
  const { image, room, slug } = firgure;
  const { locale } = useIntl();
  const { current } = useContext(LayoutContext);

  return (
    <div className={clsx("relative z-0 aspect-square rounded-3xl", className)} {...props}>
      <Link to={`/${locale}/figure/${slug}`} className="aspect-square appearance-none">
        {current === slug && !home ? (
          <div className="flex h-full w-full items-center justify-center rounded-3xl border bg-gray-50 text-3xl font-medium text-gray-800">{index}</div>
        ) : (
          <m.div
            custom={home ? 0 : 1}
            layoutId={slug}
            layout
            style={{ borderRadius: 24 }}
            transition={{ duration: 0.3, ease: [0.45, 0.04, 0.37, 0.93] }}
            className="relative aspect-square h-full w-full overflow-hidden"
          >
            <GatsbyImage
              image={getImage(image.childImageSharp?.gatsbyImageData!)!}
              alt={room}
              className={"z-0 aspect-square h-full"}
              objectFit="cover"
              objectPosition="center"
              imgClassName="aspect-square"
            />
            {!withoutText && (
              <div className="absolute inset-0 z-10 flex h-full w-full items-end overflow-hidden rounded-3xl">
                <m.div
                  variants={SLIDE_IN_FRON_BOTTOM}
                  initial="hidden"
                  exit="hidden"
                  animate="shown"
                  className="flex h-full w-full items-center justify-center rounded-3xl bg-gradient-to-b from-black/0 to-black/50 text-center content-['']"
                >
                  <h2 className="inline align-middle text-2xl font-medium text-white">{room}</h2>
                </m.div>
              </div>
            )}
          </m.div>
        )}
      </Link>
    </div>
  );
};
