import React, { Fragment, useRef, useState } from "react";
import { changeLocale, injectIntl, useIntl } from "gatsby-plugin-intl";

/* Presentational things */
import { Link } from "gatsby";
import { Button } from "../components/button";
import { Transition, Dialog } from "@headlessui/react";
import { CloseIcon, HamburgerIcon } from "../assets/icons";
import { MadameTussaudsLogo } from "../assets/logos";

const Header = () => {
  const { formatMessage, locale } = useIntl();

  const [open, setOpen] = useState(false);
  const _languageRef = useRef(null);

  const languages = [
    { id: "hu", name: "Magyar" },
    { id: "en", name: "English" },
  ];

  // const onChangeLanguage = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   changeLocale(e.currentTarget.name);
  // };

  const handleMenuOpen = () => setOpen(true);
  const handleMenuClose = () => setOpen(false);

  return (
    <header className="sticky -top-px z-50 bg-mt-red">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleMenuClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                as="nav"
                role="navigation"
                id="menu"
                aria-label="menu"
                className="relative flex w-full max-w-screen-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl"
              >
                <Button
                  icon={<CloseIcon aria-expanded={open} className="h-12 w-12" aria-hidden="true" focusable="false" />}
                  tertiary
                  className="-mb-2 aspect-square h-16 w-fit pl-2 text-gray-700 hover:text-gray-800"
                  onClick={handleMenuClose}
                />

                <Link
                  to={"/" + locale + "/"}
                  onClick={handleMenuClose}
                  className="border-gray-200 px-4 pb-6 pt-3 text-xl font-semibold text-gray-900"
                  aria-label={formatMessage({ id: "zones" })}
                >
                  {formatMessage({ id: "zones" })}
                </Link>

                <label aria-labelledby="language" className="flex items-baseline gap-2 border-t border-gray-200 px-4 py-6 text-xl">
                  <span id="language" className="font-semibold text-gray-900">
                    {formatMessage({ id: "language" })}:
                  </span>
                  <select
                    ref={_languageRef}
                    value={locale}
                    defaultValue={locale}
                    aria-label={formatMessage({ id: "changeLanguage" })}
                    className="flex appearance-none items-center bg-transparent text-gray-700 hover:text-gray-800 focus:border-transparent focus:outline-none focus:ring-0"
                    onChange={(e) => changeLocale(e.currentTarget.value)}
                  >
                    {languages.map((language) => (
                      <option key={language.id} value={language.id} aria-label={formatMessage({ id: "language" }) + `: ${language.name}`}>
                        {language.name}
                      </option>
                    ))}
                  </select>
                </label>

                <a href="https://www.madametussauds.hu" className="border-t border-gray-200 px-4 py-6 text-xl font-semibold text-gray-900">
                  madametussauds.hu
                </a>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <nav role="navigation" className="relative z-50 flex h-16 w-full items-center justify-between gap-4">
        <button type="button" className="p-2 pl-4 text-white" aria-expanded={!open} onClick={handleMenuOpen} aria-controls="menu">
          <HamburgerIcon className="h-8 w-8" aria-hidden="true" focusable={false} />
        </button>

        {/* Logo */}
        <Link to={"/" + locale + "/"} className="absolute inset-0 m-auto h-full w-fit" aria-label="Főoldal" aria-expanded={!open}>
          <MadameTussaudsLogo className="mx-auto h-full w-auto fill-mt-gold-dark px-4 py-2" focusable="false" />
        </Link>

        {/* <div className="flex items-center gap-2 px-2 text-white">
          <button key="en" name="en" aria-label="Change language to English" onClick={onChangeLanguage}>
            EN
          </button>
          <button key="hu" name="hu" aria-label="Nyelv váltása Magyarra" onClick={onChangeLanguage} className="border-l border-white pl-2">
            HU
          </button>
        </div> */}
      </nav>
    </header>
  );
};

export default injectIntl(Header);
