import React, { createContext, useMemo, useState } from "react";

interface LayoutContextProps {
  figures: Queries.FigurePreviewFragment[];
  current: string;
  setFigures: React.Dispatch<React.SetStateAction<Queries.FigurePreviewFragment[]>>;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
}

export const LayoutContext = createContext<LayoutContextProps>({
  figures: [],
  current: "",
  setFigures: () => {},
  setCurrent: () => {},
});

export const LayoutProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [figures, setFigures] = useState<Queries.FigurePreviewFragment[]>([]);
  const [current, setCurrent] = useState<string>("");

  const figuresInOrder = useMemo(() => figures.sort((a, b) => a.order - b.order), [figures]);

  return <LayoutContext.Provider value={{ figures: figuresInOrder, setFigures, current, setCurrent }}>{children}</LayoutContext.Provider>;
};
